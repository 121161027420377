/* src/components/HeroSection.css */

.hero-section {
  position: relative;
  overflow: hidden;
}

.hero-background {
  background-color: var(--color-background);
  background-image: radial-gradient(
    circle at 3% 3%,
    rgba(255, 255, 255, 0.15),
    transparent 15%
  );
  background-size: 50% 50%;
}