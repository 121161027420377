/* src/components/ContactForm.css */

/* Define CSS Variables */
:root {
  --color-accent: #1E90FF; /* Blue */
  --color-secondary: #000000; /* Black */
  --color-text: #FFFFFF; /* White */
  --color-buttonText: #FFFFFF; /* White */
  --color-error: #FF4B4B; /* Red for errors */
  --color-success: #4BB543; /* Green for success */
}

/* General Styles for Form Fields */
.form-control {
  width: 100%;
  padding: 10px;
  background: var(--color-secondary);
  color: var(--color-text);
  border: 1px solid var(--color-accent);
  border-radius: 0.375rem;
  font-size: 16px;
  box-sizing: border-box;
}

.form-control:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-accent);
}

/* Phone Input Container */
.phone-input-container {
  display: flex;
  gap: 10px; /* Small gap between country code and phone number */
  align-items: center;
  width: 100%;
}

/* Country Code Input Styles */
.country-code-input {
  width: 100px; /* Fixed width for country code */
  padding: 10px;
  background: var(--color-secondary);
  color: var(--color-text);
  border: 1px solid var(--color-accent);
  border-radius: 0.375rem;
  font-size: 16px;
  box-sizing: border-box;
}

.country-code-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-accent);
}

/* Phone Number Input Styles */
.phone-number-input {
  flex: 1; /* Take up remaining space */
  padding: 10px;
  background: var(--color-secondary);
  color: var(--color-text);
  border: 1px solid var(--color-accent);
  border-radius: 0.375rem;
  font-size: 16px;
  box-sizing: border-box;
}

.phone-number-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-accent);
}

/* Button Styles */
button {
  background: var(--color-accent);
  color: var(--color-buttonText);
  border: none;
  padding: 10px 20px;
  border-radius: 0.375rem;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

button:hover {
  opacity: 0.9;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Feedback Message Styles */
.feedback-message {
  margin-top: 1rem;
  font-size: 14px;
}

.feedback-success {
  color: var(--color-success);
}

.feedback-error {
  color: var(--color-error);
}

/* Label Styles */
label {
  color: var(--color-text);
  font-size: 14px;
  margin-bottom: 0.25rem;
  display: block;
}
