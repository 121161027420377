/* src/index.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #FFFFFF;
  --color-secondary: #1A1A1A;
  --color-text: #E5E5E5;
  --color-light-text: #E5E5E5;
  --color-background: #0D0D0D;
  --color-accent: #FFB800;      /* Warmer golden yellow */
  --color-accent-light: #FFD700; /* Gold */
  --color-accent-dark: #CC9900;  /* Dark gold */
  --color-header: #0D0D0D;
  --color-button-text: #000000;
  --color-error: #EF4444;
  --color-success: #22C55E;
  --color-warning: #F59E0B;
  --color-accent-glow: rgba(255, 184, 0, 0.15);
}

body {
  font-family: var(--font-primary);
  background-color: var(--color-background);
  color: var(--color-text);
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--color-primary);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

a {
  color: var(--color-accent);
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: var(--color-accent-light);
  transform: translateY(-1px);
}

/* Form Elements */
input,
textarea,
select {
  background-color: var(--color-secondary);
  color: var(--color-text);
  border: 1px solid var(--color-light-text);
  border-radius: 8px;
  padding: 0.75rem 1rem;
  width: 100%;
  transition: all 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--color-accent);
  box-shadow: 0 0 0 3px var(--color-accent-glow);
}

/* Buttons */
button {
  background-color: var(--color-accent);
  color: var(--color-button-text);
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

button:hover {
  background-color: var(--color-accent-light);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px var(--color-accent-glow);
}

button:active {
  transform: translateY(0);
}

/* Button Animation */
.btn-animate {
  position: relative;
  overflow: hidden;
}

.btn-animate::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
}

.btn-animate:hover::before {
  width: 300%;
  height: 300%;
}

/* Hero Section */
.hero-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-background);
  background-image: 
    radial-gradient(circle at 3% 3%, rgba(255, 255, 255, 0.15) 0%, transparent 15%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.hero-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    var(--color-background) 100%
  );
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

/* Cards */
.card {
  background-color: var(--color-secondary);
  border-radius: 0.5rem;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px var(--color-accent-glow);
}

/* Background Patterns */
.bg-pattern {
  background-image: radial-gradient(
    circle at 2px 2px,
    var(--color-accent) 1px,
    transparent 0
  );
  background-size: 40px 40px;
}

.bg-gradient {
  background: linear-gradient(
    135deg,
    var(--color-background) 0%,
    var(--color-secondary) 100%
  );
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--color-secondary);
}

::-webkit-scrollbar-thumb {
  background: var(--color-accent);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-accent-light);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { 
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-banner {
  background-size: 200% 200%;
  animation: gradientShift 15s ease infinite;
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in;
}

.animate-slide-up {
  animation: slideUp 0.5s ease-out;
}

/* Utility Classes */
.text-gradient {
  background: linear-gradient(to right, var(--color-accent), var(--color-accent-light));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.glassmorphism {
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .container {
    @apply px-4 sm:px-6 lg:px-8;
  }
  
  .text-responsive {
    @apply text-base sm:text-lg md:text-xl lg:text-2xl;
  }
  
  .heading-responsive {
    @apply text-2xl sm:text-3xl md:text-4xl lg:text-5xl;
  }
  
  .p-responsive {
    @apply p-4 sm:p-6 md:p-8 lg:p-10;
  }
  
  .m-responsive {
    @apply m-4 sm:m-6 md:m-8 lg:m-10;
  }
}

/* Mobile-first media queries */
@media (max-width: 640px) {
  .hero-section {
    min-height: 100svh;
  }
  
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  h1, h2, h3 {
    word-wrap: break-word;
    hyphens: auto;
  }
  
  .card {
    margin: 1rem 0;
  }
}

/* Fix for mobile safari viewport height */
@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
}

/* Prevent content shift on mobile */
html {
  scroll-padding-top: 80px;
}

/* Improve touch targets on mobile */
button, a {
  @apply min-h-[44px] min-w-[44px];
  touch-action: manipulation;
}